module.exports = {
  siteTitle: '@Jmlevick - Web Developer', // <title>
  manifestName: 'Jmlevick, Developer',
  manifestShortName: 'Jmlevick', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#FFFFFF',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Manuel Escudero',
  heading: 'Web Developer',
  // social
  socialLinks: [
    {
      icon: 'fa-gitlab',
      name: 'Gitlab',
      url: 'https://gitlab.com/jmlevick',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/jmlevick',
    },
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://linkedin.com/in/jmescudero',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:code@jmlevick.me',
    },
  ],
};

import React from 'react';
import config from '../../config';

export default function PageFooter() {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>&copy; {config.authorName}</li>
        <li>
          Photo by <a href="https://foter.co/a3/f8606a">Hotice Hsu</a> on <a href="https://foter.com/re5/e82952">Foter.com</a> / <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC BY-SA</a>
        </li>
      </ul>
    </footer>
  );
}
